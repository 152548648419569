import anime from 'animejs';

const LANG = document.querySelector('html').lang;

class Animate {
  observer;

  static optionsObserver = {
    root: null,
    rootMargin: '0px',
    threshold: [0, 0.5, 1],
  };

  constructor() {
    this._onEndAnimate = this._onEndAnimate.bind(this);
    this.observer = new IntersectionObserver(this._revealAnimate.bind(this), this.constructor.optionsObserver);
  }

  _jsAnimate(elem, data) {
    const numberFormater =
      Intl && Intl.NumberFormat
        ? new Intl.NumberFormat(LANG, data.styleFormat ? data.styleFormat : {})
        : { format: (n) => n };

    switch (data.name) {
      case 'increase': {
        anime({
          ...data,
          targets: data.targets,

          begin: () => {
            elem.classList.add('animate');
          },

          update: () => {
            elem.innerHTML = numberFormater.format(data.targets.value) + '%';
          },

          complete: () => {
            this._endAnimate(elem);
          },
        });
        break;
      }
    }
  }

  _onEndAnimate(e) {
    if (e.target === e.currentTarget || e.currentTarget.tagName === 'svg') {
      this._endAnimate(e.currentTarget);
    }
  }

  _endAnimate(elem) {
    elem.classList.remove('animate');
    const animateSettings = elem.dataset && elem.dataset.jsAnimate ? JSON.parse(elem.dataset.jsAnimate) : null;
    if (animateSettings && animateSettings.iteration === 'infinite') {
      return;
    }
    this.remove([elem]);
  }

  _revealAnimate(entries) {
    entries.forEach((entry) => {
      if (entry.target.classList.contains('animate')) {
        return;
      }

      if (entry.isIntersecting) {
        const lastNoneIntersecting = entry.target.__lastNoneIntersecting__;

        let isFromBottom = entry.intersectionRect.top > entry.rootBounds.height - entry.intersectionRect.bottom;
        let isIntersectionHeightSufficient =
          entry.intersectionRect.height / entry.boundingClientRect.height > 0.4 || entry.intersectionRatio >= 1;

        if (
          !isFromBottom &&
          lastNoneIntersecting &&
          lastNoneIntersecting.boundingClientRect.top > lastNoneIntersecting.rootBounds.height
        ) {
          isFromBottom = true;
          isIntersectionHeightSufficient = true;
        }

        let isFromLeftOrRight = entry.intersectionRect.width < entry.boundingClientRect.width;
        let isIntersectionWidthSufficient = entry.intersectionRatio >= 0.4;

        if (
          !isFromLeftOrRight &&
          lastNoneIntersecting &&
          (lastNoneIntersecting.boundingClientRect.left < 0 ||
            lastNoneIntersecting.boundingClientRect.right > lastNoneIntersecting.rootBounds.width)
        ) {
          isFromLeftOrRight = true;
          isIntersectionWidthSufficient = true;
        }

        delete entry.target.__lastNoneIntersecting__;

        if (isFromBottom || isFromLeftOrRight) {
          if (
            ((isFromBottom && isIntersectionHeightSufficient) ||
              (isFromLeftOrRight && isIntersectionWidthSufficient)) &&
            entry.target.classList.contains('invisible')
          ) {
            entry.target.classList.remove('invisible');
            const animateSettings =
              entry.target.dataset && entry.target.dataset.jsAnimate
                ? JSON.parse(entry.target.dataset.jsAnimate)
                : null;
            if (animateSettings && animateSettings.name) {
              this._jsAnimate(entry.target, animateSettings);
            } else {
              entry.target.classList.add('animate');
            }
          }
        } else {
          entry.target.classList.remove('invisible');
        }
      } else {
        entry.target.classList.add('invisible');
        entry.target.__lastNoneIntersecting__ = {
          boundingClientRect: entry.boundingClientRect,
          rootBounds: entry.rootBounds,
        };
      }
    });
  }

  add(elems) {
    elems.forEach((entity) => {
      if (entity.animateObserver) {
        return;
      }
      entity.animateObserver = true;
      ['webkitAnimationEnd', 'mozAnimationEnd', 'MSAnimationEnd', 'oanimationend', 'animationend'].forEach(
        (eventType) => {
          entity.addEventListener(eventType, this._onEndAnimate);
        },
      );

      this.observer.observe(entity);
    });
  }

  remove(elems) {
    elems.forEach((entity) => {
      ['webkitAnimationEnd', 'mozAnimationEnd', 'MSAnimationEnd', 'oanimationend', 'animationend'].forEach(
        (eventType) => {
          entity.removeEventListener(eventType, this._onEndAnimate);
        },
      );

      this.observer.unobserve(entity);
      if (entity.animateObserver) {
        delete entity['animateObserver'];
      }
      entity.classList.remove('invisible');
    });
  }
}

export default new Animate();
