const defaultOptions = {
  disableHistoryReplacing: false,
};

export const handleDefaultModal = (modalId, onClose = null, options = defaultOptions) => {
  const modal = document.querySelector(modalId);
  const closeBtn = modal.querySelectorAll('[data-modal=close-modal]');

  let oldURL = null;

  window.addEventListener('hashchange', (e) => {
    oldURL = e.oldURL;
  });

  const handleCloseModal = () => {
    if (!options.disableHistoryReplacing) {
      if (oldURL && !oldURL.match('#privacy-policy')) {
        window.history.back();
      } else {
        location.hash = '';
        history.replaceState('', '', location.pathname);
      }
    }

    modal.style.visibility = 'hidden';
    modal.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    modal.classList.remove('visible');
    if (!document.body.classList.contains('disable')) document.body.style.overflow = 'auto';
    closeBtn.forEach((btn) => btn.removeEventListener('click', handleCloseModal));
    modal.removeEventListener('click', handleCloseModalByWrapper);

    if (onClose) {
      onClose();
    }
  };

  // window.addEventListener('hashchange', (e) => {
  //   modal.style.visibility = 'hidden';
  //   modal.style.backgroundColor = 'rgba(0, 0, 0, 0)';
  //   modal.classList.remove('visible');
  //   if (!document.body.classList.contains('disable')) document.body.style.overflow = 'auto';
  //   closeBtn.forEach((btn) => btn.removeEventListener('click', handleCloseModal));
  // })

  const handleCloseModalByWrapper = (e) => {
    if (e.target.classList.contains('modal-wrapper')) {
      handleCloseModal();
    }
  };

  closeBtn.forEach((btn) => btn.addEventListener('click', handleCloseModal));
  modal.addEventListener('click', handleCloseModalByWrapper);

  modal.style.visibility = 'visible';
  modal.style.backgroundColor = 'rgba(0, 0, 0, 0.6)';
  modal.classList.add('visible');
  if (!document.body.classList.contains('disable')) document.body.style.overflow = 'hidden';
};
