import Swiper, { Pagination, Autoplay } from 'swiper';
import InitAutoplay from '../../scripts/initAutoplay';
Swiper.use([Pagination, Autoplay]);

let scheduleSlider = null;

const scheduleMobileConfig = {
  navigation: {
    nextEl: '.er_schedule__inner__mobile__arrows__next',
    prevEl: '.er_schedule__inner__mobile__arrows__prev',
  },
  pagination: {
    el: '.er_schedule__inner__mobile__pagination',
    clickable: true,
    spaceBetween: 30,
    resizeObserver: true,
    grabCursor: true,
  },
};

const initSlider = () => {
  if (window.matchMedia('(max-width: 1024px)').matches && !!!scheduleSlider) {
    scheduleSlider = new Swiper('.er_schedule__inner__mobile_container', scheduleMobileConfig);
  }

  if (scheduleSlider && window.matchMedia('(max-width: 1024px)').matches) {
    window.addEventListener('scroll', () => {
      InitAutoplay(scheduleSlider, 'er_schedule', 6000);
    });
  }
};

export default () => {
  initSlider();
  window.addEventListener('resize', initSlider, false);
};
