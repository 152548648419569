export default function initAutoPlayVideo() {
  const videoContainer = document.querySelector('.js-video-bg');
  const video = videoContainer.querySelector('video');
  video.addEventListener('loadeddata', function () {
    if (video.readyState >= 2) {
      const catchFunc = function (e) {
        console.error('video.play()', e.message);
        videoContainer.hidden = true;
      };
      try {
        const prom = video.play();
        if (prom && prom.catch) {
          prom.catch(catchFunc);
        }
      } catch (e) {
        catchFunc.call(this, e);
      }
    }
  });
}
