import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);

export default () => {
  const eventsSlider = new Swiper('.er_events__slider_container', {
    slidesPerView: 'auto',
    resizeObserver: true,
    navigation: {
      nextEl: '.er_events__slider__buttons__item_next',
      prevEl: '.er_events__slider__buttons__item_prev',
    },
    noSwipingClass: 'er_events__slider__wrapper__slide__button',
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
        freeMode: false,
      },

      481: {
        slidesPerView: 2,
        spaceBetween: 30,
        slidesOffsetBefore: 40,
        slidesOffsetAfter: 40,
        freeMode: true,
      },

      1025: {
        slidesPerView: 'auto',
        spaceBetween: 40,
        slidesOffsetBefore: 220,
        slidesOffsetAfter: 220,
        freeMode: true,
      },
    },
  });

  const sliderElements = document.querySelectorAll('.er_events__slider__wrapper__slide');

  let isInit = false;

  function initPadding(all = false) {
    if (all) {
      for (let i = 0; i < sliderElements.length; i++) {
        sliderElements[i].style.paddingTop =
          String((sliderElements[i].offsetHeight - sliderElements[i].children[0].offsetHeight) / 2) + 'px';
      }
    } else {
      this.style.paddingTop = String((this.offsetHeight - this.children[0].offsetHeight) / 2) + 'px';
    }
  }

  function hoverPadding(el) {
    this.style.paddingTop =
      String((this.offsetHeight - this.children[0].offsetHeight - this.children[1].offsetHeight) / 2) + 'px';
  }

  function resetPadding() {
    for (let i = 0; i < sliderElements.length; i++) {
      sliderElements[i].style.paddingTop = '20px';
    }
  }

  function toggleHidden() {
    if (window.matchMedia('(min-width: 1025px)').matches && !isInit) {
      for (let i = 0; i < sliderElements.length; i++) {
        initPadding(sliderElements, true);
        sliderElements[i].addEventListener('mouseenter', hoverPadding, false);
        sliderElements[i].addEventListener('mouseleave', initPadding, false);
      }
      isInit = true;
    } else if (window.matchMedia('(max-width: 1024px)').matches) {
      resetPadding();
      for (let i = 0; i < sliderElements.length; i++) {
        sliderElements[i].removeEventListener('mouseenter', hoverPadding);
        sliderElements[i].removeEventListener('mouseleave', initPadding);
        isInit = false;
      }
    }
  }

  toggleHidden();

  window.addEventListener('resize', toggleHidden, false);
};
