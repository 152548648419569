import Swiper, { Autoplay } from 'swiper';
import InitAutoplay from '../../scripts/initAutoplay';
Swiper.use([Autoplay]);

const clientsConfig = {
  resizeObserver: true,
  loop: true,
  loopFillGroupWithBlank: true,
  speed: 1500,
  allowTouchMove: false,
  breakpoints: {
    320: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    480: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
    667: {
      slidesPerView: 5,
      spaceBetween: 20,
    },
    932: {
      slidesPerView: 6,
      spaceBetween: 30,
    },
    1279: {
      slidesPerView: 8,
      spaceBetween: 30,
    },
  },
};

export default () => {
  const clientsSliderTop = new Swiper('.er_clients_container_top', clientsConfig);
  const clientsSliderBottom = new Swiper('.er_clients_container_bottom', clientsConfig);
  window.addEventListener('scroll', () => {
    if (window.matchMedia('(max-width: 1279px)').matches) {
      if (!clientsSliderTop.autoplay.running || !clientsSliderBottom.autoplay.running) {
        InitAutoplay(clientsSliderTop, 'er_clients', 1);
        InitAutoplay(clientsSliderBottom, 'er_clients', 1, true);
      }
    } else {
      clientsSliderTop.autoplay.stop();
      clientsSliderBottom.autoplay.stop();
    }
  });
};
