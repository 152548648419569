const header = document.querySelector('header');

function initArrows(el, i, className, mobile = false) {
  const arrowIcon = document.createElement('div');
  arrowIcon.classList.add(className);
  arrowIcon.innerHTML =
    '<svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
    '<path fill-rule="evenodd" clip-rule="evenodd" d="M6.35355 8.35355C6.15829 8.54882 5.84171 8.54882 5.64645 8.35355L2.64645 5.35355C2.45118 5.15829 2.45118 4.84171 2.64645 4.64645C2.84171 4.45118 3.15829 4.45118 3.35355 4.64645L6 7.29289L8.64645 4.64645C8.84171 4.45118 9.15829 4.45118 9.35355 4.64645C9.54882 4.84171 9.54882 5.15829 9.35355 5.35355L6.35355 8.35355Z" fill="currentColor"/>\n' +
    '</svg>\n';
  if (mobile) {
    el[i].children[0].appendChild(arrowIcon);
  } else {
    el[i].appendChild(arrowIcon);
  }
}

function initItems(el) {
  for (let i = 0; i < el.length; i++) {
    if (el[i].children.length > 1) {
      el[i].addEventListener('mouseenter', showSub, false);
      el[i].addEventListener('mouseleave', hideSub, false);

      initArrows(el, i, 'header__menu__list__item__icon');
    }
  }
}

function initMobileItems(el) {
  for (let i = 0; i < el.length; i++) {
    if (el[i].children.length > 1) {
      el[i].addEventListener('click', showSubMobile, false);
      initArrows(el, i, 'header__mobile__menu__list__item__icon', true);
    } else {
      el[i].addEventListener('click', toggleMenu, false);
    }
  }
}

function showSubMobile() {
  this.classList.toggle('header__mobile__inner__menu__list__item_nest');
  this.children[0].classList.toggle('active');
  this.children[1].classList.toggle('header__mobile__inner__menu__list__item__sub_active');
}

function showSub() {
  this.children[1].classList.add('header__menu__list__item__sub_active');
  this.children[2].classList.add('header__menu__list__item__icon_active');
  header.classList.add('hovered');
}

function hideSub() {
  this.children[1].classList.remove('header__menu__list__item__sub_active');
  this.children[2].classList.remove('header__menu__list__item__icon_active');
  header.classList.remove('hovered');
}

function fixMenu() {
  if (document.body.scrollTop >= 30 || document.documentElement.scrollTop >= 30) {
    header.classList.add('fixed');
  } else {
    header.classList.remove('fixed');
  }
}

function toggleMenu() {
  header.classList.toggle('open');
  if (document.body.style.overflow === 'auto') document.body.style.overflow = '';
  document.body.classList.toggle('disable');
}

export default () => {
  document.addEventListener('scroll', fixMenu, false);
  const el = header.getElementsByClassName('header__menu__list__item');
  initItems(el);

  const mobileEl = header.getElementsByClassName('header__mobile__inner__menu__list__item');
  initMobileItems(mobileEl);

  const mobileIcon = header.querySelector('.header__mobile_btn');
  mobileIcon.addEventListener('click', toggleMenu);
};
