import Swiper, { Navigation, Parallax, EffectFade } from 'swiper';
import InitAutoplay from '../../scripts/initAutoplay';
Swiper.use([Navigation, Parallax, EffectFade]);

const backgrounds = Array.from(document.querySelectorAll('.er_cases__backgrounds__item'));
const BACKGROUND_CLASSNAME = 'er_cases__backgrounds__item_active';
const datasetName = 'number';

function changeBackground(slideNumber) {
  const backgroundToShow = backgrounds.filter((element) => Number(element.dataset[datasetName]) === slideNumber + 1);

  if (slideNumber >= 0 && backgroundToShow.length) {
    backgrounds.forEach((element) => element.classList.remove(BACKGROUND_CLASSNAME));
    backgroundToShow.forEach((element) => element.classList.add(BACKGROUND_CLASSNAME));
  }
}

export default () => {
  const casesSlider = new Swiper('.er_cases__slider', {
    slidesPerView: 1,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    parallax: true,
    centeredSlides: true,
    noSwipingClass: 'er_cases__slider__item__container__block__link',
    navigation: {
      nextEl: '.er_cases__navigation__item_next',
      prevEl: '.er_cases__navigation__item_prev',
    },
    on: {
      slideChangeTransitionStart: () => {
        changeBackground(casesSlider.realIndex);
      },
    },
  });

  window.addEventListener('scroll', () => {
    InitAutoplay(casesSlider, 'er_cases', 12000);
  });
};
