export const scrollToElement = (elementId) => {
  const element = document.getElementById(elementId);
  if (!element) {
    return;
  }

  let yOffset = -85;

  function checkOffset() {
    if (window.matchMedia('(max-width: 1199px)').matches) {
      yOffset = -document.querySelector('header').offsetHeight;
    } else {
      yOffset = -85;
    }
  }

  checkOffset();
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.addEventListener('resize', checkOffset, false);
  window.scrollTo({ top: y, behavior: 'smooth' });
};
