const ent = Array.from(document.querySelectorAll('.er_main__switcher__item')); // slider controls (used to switch slides)
const backgrounds = document.querySelectorAll('.er_main__bck__item'); // all background slides that are going to be switched
const textDescriptions = document.querySelectorAll('.er_main__info__title__item'); // all text descriptions that are going to be switched

const TIME_TO_CHANGE_SLIDE = 12000;

let previousSlide = 1;
let interval;
let currentSlide = 1;
let isAnimating = false;

/* DOM manipulations */
const setDisplay = (element, shouldBeVisible) => {
  // element.style.display = shouldBeVisible ? 'flex' : 'none';
  // TODO: problem with auto scroll
  element.offsetWidth; // reflow
};

const hideFadedOutSlide = (prevElemNumber) => {
  setTimeout(() => {
    textDescriptions[prevElemNumber].classList.remove('er_main__info__title__item_leaving');
    backgrounds[prevElemNumber].classList.remove('er_main__bck__item_leaving');

    setDisplay(textDescriptions[prevElemNumber], false);
    setDisplay(backgrounds[prevElemNumber], false);

    isAnimating = false;
  }, 400);
};

const animateFadeOut = (prevElemNumber) => {
  textDescriptions[prevElemNumber].classList.remove('er_main__info__title__item_active');
  backgrounds[prevElemNumber].classList.remove('er_main__bck__item_active');

  textDescriptions[prevElemNumber].classList.add('er_main__info__title__item_leaving');
  backgrounds[prevElemNumber].classList.add('er_main__bck__item_leaving');

  hideFadedOutSlide(prevElemNumber);
};

const animateFadeIn = (newElemNumber) => {
  setDisplay(textDescriptions[newElemNumber], true);
  setDisplay(backgrounds[newElemNumber], true);

  textDescriptions[newElemNumber].classList.add('er_main__info__title__item_active');
  backgrounds[newElemNumber].classList.add('er_main__bck__item_active');
};

const switchProgressBarToNextSlide = (prevElemNumber, newElemNumber) => {
  ent[prevElemNumber].classList.remove('er_main__switcher__item_active', 'er_main__switcher__item_reset');
  ent[newElemNumber].classList.add('er_main__switcher__item_active');
};

const restartProgressBarAnimation = (elemNumber) => {
  ent[elemNumber].classList.remove('er_main__switcher__item_active');
  ent[elemNumber].offsetWidth; // restart animation by reflowing if the chosen element hasn't changed
  ent[elemNumber].classList.add('er_main__switcher__item_active', 'er_main__switcher__item_reset');
};

/* Slider Controls */

const startSlider = () => {
  return setTimeout(() => {
    previousSlide = currentSlide;
    if (currentSlide === ent.length) {
      currentSlide = 1;
    } else {
      currentSlide += 1;
    }
    scrollToElementX(currentSlide);
  }, TIME_TO_CHANGE_SLIDE);
};

const stopSlider = (sliderDurationTimeout) => clearTimeout(sliderDurationTimeout);

/* Positioning */
const scrollToElementX = (numberOfElement) => {
  if (isAnimating) return;
  stopSlider(interval);
  currentSlide = numberOfElement;

  const isElementTheSame = currentSlide === previousSlide;

  if (!isElementTheSame) {
    isAnimating = true;

    animateFadeOut(previousSlide - 1);

    setTimeout(() => animateFadeIn(currentSlide - 1), 100);

    switchProgressBarToNextSlide(previousSlide - 1, currentSlide - 1);
  } else {
    restartProgressBarAnimation(previousSlide - 1);
  }
  interval = startSlider();
};

let didMove = false;

const handleClick = (index) => {
  if (didMove) {
    didMove = false;
    return;
  }
  previousSlide = currentSlide;
  scrollToElementX(index);
};

Array.from(ent).forEach((item) => {
  const numberOfSlide = parseInt(item.dataset.numberofslide);
  item.addEventListener('click', () => handleClick(numberOfSlide));
  item.addEventListener('touch', () => handleClick(numberOfSlide));
});

let slideToSwitch = 0;

let touchstartX = 0;
let touchendX = 0;

const slider = document.querySelector('.er_main');

function handleGesture() {
  if (touchendX < touchstartX) {
    if (currentSlide === ent.length) {
      slideToSwitch = 1;
    } else {
      slideToSwitch = currentSlide + 1;
    }
    handleClick(slideToSwitch);
  }
  if (touchendX > touchstartX) {
    if (currentSlide === 1) {
      slideToSwitch = ent.length;
    } else {
      slideToSwitch = currentSlide - 1;
    }
    handleClick(slideToSwitch);
  }
}

slider.addEventListener('touchstart', (e) => {
  touchstartX = e.changedTouches[0].screenX;
});

slider.addEventListener('touchend', (e) => {
  touchendX = e.changedTouches[0].screenX;
  handleGesture();
});

export default () => {
  backgrounds[0].classList.add('er_main__bck__item_active');
  textDescriptions[0].classList.add('er_main__info__title__item_active');
  ent[0].classList.add('er_main__switcher__item_active');

  interval = startSlider();
};
