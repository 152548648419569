export default () => {
  const languageCheckerKZUrl = process.env.APP_ENV === 'prod' ? 'https://eventrocks.kz' : 'https://test.eventrocks.kz';

  const kzLink = document.getElementById('lang_switcher__inner__kz');
  if (kzLink) {
    kzLink.setAttribute('href', languageCheckerKZUrl);
  }

  let switchBtn = document.querySelector('.lang_switcher');

  switchBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    switchBtn.classList.toggle('open');
  });

  window.addEventListener('click', () => {
    if (switchBtn.classList.contains('open')) switchBtn.classList.remove('open');
  });
};
