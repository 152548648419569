import { getCookie } from '../../scripts/getCookie';
import { yandexMetrikaId } from '../../constants';
import { reportFormSubmissionError } from '../../../common/Sentry';
import { handleDefaultModal } from '../../scripts/handleDefaultModal';

export default function initForm() {
  const inputs = document.querySelectorAll('.er_form__block__container__fields__input input');

  const baseUrl =
    process.env.APP_ENV === 'prod' ? 'https://admin.eventicious.ru' : 'https://admin-test.eventicious.ru';

  let isPhoneValid = true;
  let isEmailValid = true;

  inputs.forEach((input) => {
    input.addEventListener('invalid', validate);
    input.addEventListener('input', removeError);
  });

  function removeError(e) {
    e.preventDefault();
    if (e.target.parentNode.classList.contains('error')) e.target.parentNode.classList.remove('error');
  }

  function validate(e) {
    e.preventDefault();
    e.target.parentNode.classList.add('error');
  }

  const formSelectors = '#main_form ,#modal_form';
  const demonstrationFormsList = document.querySelectorAll(formSelectors);

  demonstrationFormsList.forEach((demonstrationForm) => initDemonstrationForm(demonstrationForm));

  function initDemonstrationForm(demonstrationForm) {
    const nameField = demonstrationForm.querySelector('[name=name]');
    const emailField = demonstrationForm.querySelector('[name=email]');
    const phoneField = demonstrationForm.querySelector('[name=phone]');
    const messageField = demonstrationForm.querySelector('[name=message]');
    const modalHeaderInput = demonstrationForm.querySelector('input[name=modal-header]');
    const submitButton = demonstrationForm.querySelector('button[type=submit]');

    demonstrationForm.addEventListener('submit', onSubmit);

    demonstrationForm.querySelector('button').addEventListener('click', () => {
      checkPhone();
      // checkEmail();
    });

    phoneField.addEventListener('keypress', (e) => {
      let key = String.fromCharCode(e.keyCode || e.which);
      const regex = /[0-9|+()\- ]/;
      if (!regex.test(key)) {
        e.returnValue = false;
        // e.srcElement.value = '';
        if (e.preventDefault) e.preventDefault();
      }
    });

    function checkPhone() {
      const regex = /^(([0-9]|\+[0-9])[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
      if (!regex.test(phoneField.value)) {
        isPhoneValid = false;
        phoneField.parentNode.classList.add('error');
      } else {
        isPhoneValid = true;
      }
    }

    //TODO: добавить кириллицу
    function checkEmail() {
      const regex =
        /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(?:\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@(?:[a-z0-9]([-a-z0-9]{0,61}[a-z0-9]))*\.(?:[a-z]([a-z]+))$/;
      if (!regex.test(emailField.value)) {
        isEmailValid = false;
        emailField.parentNode.classList.add('error');
      } else {
        isEmailValid = true;
      }
    }

    async function onSubmit(e) {
      e.preventDefault();

      checkPhone();
      // checkEmail();

      if (isPhoneValid && isEmailValid) {
        nameField.disabled = true;
        emailField.disabled = true;
        phoneField.disabled = true;
        messageField.disabled = true;
        submitButton.disabled = true;
        submitButton.classList.add('button--loading');

        const modalTarget = modalHeaderInput ? ` [${modalHeaderInput.value}]` : '';

        const payload = {
          name: nameField.value,
          email: emailField.value,
          phone: phoneField.value,
          LandingType: 'Eventicious',
          source: 'Лендинг Event Rocks, KZ-RU' + modalTarget,
          confirm: 'true',
          roistatVisitId: getCookie('roistat_visit'),
          time: getDate(),
          gcLid: getCookie('gcLid'),
          message: `${messageField.value}\u200B\u200B\u200B`,
        };

        try {
          const answer = await fetch(`${baseUrl}/api/subscribe/request_demo_new`, {
            body: JSON.stringify(payload),
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
          });

          if (answer.status === 200) {
            demonstrationForm.classList.add('success');
            window.ym?.(yandexMetrikaId, 'reachGoal', 'form_send');
            demonstrationForm.reset();
          } else {
            throw new Error(answer);
          }
        } catch (error) {
          reportFormSubmissionError(
            [
              {
                name: 'response',
                data: error,
              },
              {
                name: 'payload',
                data: payload,
              },
            ],
            'kz-ru',
          );

          handleDefaultModal('#error-modal', null, { disableHistoryReplacing: true });
        } finally {
          nameField.parentElement.classList.remove('error');
          emailField.parentElement.classList.remove('error');
          phoneField.parentElement.classList.remove('error');
          nameField.disabled = false;
          emailField.disabled = false;
          phoneField.disabled = false;
          messageField.disabled = false;
          submitButton.disabled = false;
          submitButton.classList.remove('button--loading');
        }
      }
    }
  }

  function getDate() {
    const d = new Date();

    const to2Digit = function (number) {
      return number < 10 ? '0' + number : number;
    };

    return `${d.getFullYear()}-${to2Digit(d.getMonth() + 1)}-${to2Digit(d.getDate())}, ${to2Digit(
      d.getHours(),
    )}:${to2Digit(d.getMinutes())}:${to2Digit(d.getSeconds())}`;
  }
}
