export default (carousel, parentClass, autoplayDelay = 3000, reverseDirection = false, stop = false) => {
  const otherCasesSlider = document.querySelector('.other-cases-swiper');

  if (carousel.autoplay.running || otherCasesSlider) {
    return;
  }

  let current = carousel.wrapperEl;

  while (!current.classList.contains(parentClass)) {
    current = current.parentNode;
  }

  const bounding = current.getBoundingClientRect();
  const screenHeight = (window.innerHeight / 3) * 2;

  if (bounding.top < screenHeight) {
    carousel.autoplay.start();
    carousel.params.autoplay.delay = autoplayDelay;
    carousel.params.autoplay.reverseDirection = reverseDirection;
    carousel.params.autoplay.disableOnInteraction = false;
  }

  if (stop) carousel.autoplay.stop();
};
