export default (function (section, scrollWrapper) {
  const descriptions = section.querySelectorAll('.er_schedule__inner__content__text__item');

  const setActiveDescription = (index) => {
    const image = section.querySelector('.er_schedule__inner__content__figure');
    const pictures = image.querySelectorAll('.er_schedule__inner__content__figure__picture');
    descriptions.forEach((description, i) => {
      if (i === index) {
        description.classList.add('er_schedule__inner__content__text__item_active');
        pictures[i].classList.add('er_schedule__inner__content__figure__picture_active');
      } else {
        description.classList.remove('er_schedule__inner__content__text__item_active');
        pictures[i].classList.remove('er_schedule__inner__content__figure__picture_active');
      }
    });
  };

  const scrollToActiveDescription = (index) => {
    let { scrollTop, scrollHeight } = scrollWrapper;
    if (typeof scrollHeight === 'undefined') scrollHeight = document.querySelector('body').scrollHeight;
    if (typeof scrollTop === 'undefined') ({ scrollY: scrollTop } = scrollWrapper);
    const descriptionsWrap = section.querySelector('.er_schedule__inner__content__text');
    let calculatedYPosition = descriptionsWrap.getBoundingClientRect().top + scrollTop;

    if (index > 0) {
      calculatedYPosition = descriptions[index].getBoundingClientRect().top + scrollTop - 200;
    }

    let i = scrollTop;
    const operand = scrollTop < calculatedYPosition;
    const step = 30;
    const int = setInterval(() => {
      if (operand) {
        i += step;
        if (i >= calculatedYPosition) i = calculatedYPosition;
      } else {
        i -= step;
        if (i <= calculatedYPosition) i = calculatedYPosition;
      }

      if (scrollWrapper.scroll) scrollWrapper.scroll(0, i);
      else scrollWrapper.scrollTop = i;

      if (i === calculatedYPosition) {
        setActiveDescription(index);
        clearInterval(int);
      }
    }, 10);
  };

  const onScroll = (e) => {
    const image = section.querySelector('.er_schedule__inner__content__figure');
    const { top } = image.getBoundingClientRect();

    if (Math.floor(top) <= 0) {
      for (let i = 0; descriptions.length > i; i++) {
        let { top: yDescription } = descriptions[i].getBoundingClientRect();
        if (yDescription > 110) {
          setActiveDescription(i);
          break;
        }
      }
    }
  };

  const action = () => {
    if (window.matchMedia('(min-width: 1025px)').matches) {
      descriptions.forEach((description) => {
        description.addEventListener('click', () => {
          const activeDescriptionIndex = Array.from(descriptions).indexOf(description);

          scrollToActiveDescription(activeDescriptionIndex);
        });
      });

      scrollWrapper.addEventListener('scroll', onScroll);
    } else {
      descriptions.forEach((description) => {
        description.removeEventListener(
          'click',
          () => {
            const activeDescriptionIndex = Array.from(descriptions).indexOf(description);

            scrollToActiveDescription(activeDescriptionIndex);
          },
          false,
        );
      });

      scrollWrapper.removeEventListener('scroll', onScroll, false);
    }
  };

  action();

  window.addEventListener('resize', action, false);
});
