export default () => {
  const modal = document.querySelector('.er_cookie');

  const submitBtn = modal.querySelector('button');

  const isShowCookieModal = () => {
    const cookie = document.cookie.match('agreed');
    return cookie === null;
  };

  const showModal = () => {
    if (isShowCookieModal()) {
      modal.style.display = 'flex';
      submitBtn.addEventListener('click', handleClick);
    }
  };

  const handleClick = () => {
    modal.style.display = 'none';
    let date = new Date(Date.now() + 86400e3);
    date = date.toUTCString();
    document.cookie = 'agreed=true; expires=' + date;
  };

  document.addEventListener('DOMContentLoaded', showModal);
};
