import { isVisibleFormModal } from '../../scripts/isVisibleFormModal';

export default function privacyPolicy() {
  const privacyModal = document.querySelector('#privacy-policy');

  let oldURL = null;

  const closeModal = () => {
    if (oldURL) {
      window.history.back();
    } else {
      location.href = '/';
    }
  };

  window.addEventListener('hashchange', (e) => {
    oldURL = e.oldURL;
    if (e.newURL.match('#privacy-policy')) {
      if (!document.body.classList.contains('disable')) document.body.style.overflow = 'hidden';
    } else if (e.oldURL.match('#privacy-policy') && !isVisibleFormModal()) {
      if (!document.body.classList.contains('disable')) document.body.style.overflow = 'auto';
    }
  });

  window.addEventListener('load', (e) => {
    if (location.href.match('#privacy-policy')) {
      if (!document.body.classList.contains('disable')) document.body.style.overflow = 'hidden';
    }
  });

  privacyModal.addEventListener('click', (e) => {
    if (e.target.classList.contains('modal-wrapper')) {
      closeModal();
    }
  });

  privacyModal.querySelector('.modal-close').addEventListener('click', () => {
    closeModal();
  });
}
